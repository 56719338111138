<template>
  <div class="auth-content align-center" v-if="template_status">
    <img src="~assets/images/auth/auth-top-bg.png" class="auth-top-bg" alt="" />
    <img src="~assets/images/auth/auth-bot-bg.png" class="auth-bot-bg" alt="" />
    <div class="auth-wrap">
      <img src="~assets/images/auth/auth-bg.png" class="auth-bg" alt="" />
      <img src="~assets/images/auth/auth-logo.png" class="auth-logo" alt="" />
      <p class="auth-hint-p">医生为本，取之于医，用之于医</p>
      <!--  输入内容  -->
      <div class="auth-inp-box">
        <p class="auth-inp-tit">选择登录{{ field }}</p>
        <div class="verify-text align-center">当前登录方式已关联{{ circlesOptions.length }}个{{ field }}，请选择登录{{ field }}</div>
        <el-select v-model="circles_uuid" placeholder="请选择" class="verify-input">
          <el-option v-for="item in circlesOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
        <div class="auth-inp-cont">
          <div class="login-btn" style="margin-top: 175px" @click="submitFun">登录</div>
        </div>
      </div>
    </div>
    <div class="bottom-hint-box align-center">
      {{ $static.footer_text }}
      <a href="https://beian.miit.gov.cn" target="_blank" class="records-link">{{ $static.record_no }}</a>
    </div>
  </div>
</template>

<script>
import store from '../../store/store';
import router from '../../router/index';
export default {
  name: 'Verify',
  data() {
    return {
      circlesOptions: [],
      circles_uuid: '',
      template_status: false, // 渲染展示
      field: '',
      wechat_pad: false,
    };
  },
  created() {
    this.getCircles();
    this.wechat_pad = this.$route.query.wechat_pad;
    this.field = process.env.VUE_APP_FIELD;
  },
  methods: {
    // 获取小组列表
    getCircles() {
      let url = `${this.$api.userManagedCircles}?is_author=1`;
      this.$http.get(url, true).then((res) => {
        if (res.data.success) {
          let _data = res.data.data;
          if (_data && _data.length) {
            if (_data.length == 1) {
              localStorage.setItem('circle-uuid', _data[0].uuid);
              setTimeout(() => {
                this.$user.getUserInfo();
              }, 200);
            } else {
              this.circles_uuid = _data[0].uuid;
              for (let i = 0; i < _data.length; i++) {
                this.circlesOptions.push({
                  label: _data[i].name,
                  value: _data[i].uuid,
                });
                if (localStorage.getItem('circle-uuid') && _data[i].uuid == localStorage.getItem('circle-uuid')) {
                  this.circles_uuid = _data[i].uuid;
                }
              }
              this.template_status = true;
            }
          } else {
            this.$router.replace('/perfect');
          }
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    // 登录
    submitFun() {
      localStorage.setItem('circle-uuid', this.circles_uuid);
      let time = new Date().getTime();

      // this.$user.getUserInfo(time);
      this.getUserInfo(time);
    },

    getUserInfo(time) {
      store.dispatch('A_USER_INFO', {});
      var day = Number(localStorage.getItem('day'));
      this.$http
        .get(this.$api.userInfo, true)
        .then((res) => {
          if (res.data.success) {
            store.dispatch('A_USER_INFO', res.data.data);
            let user_info = res.data.data;
            let password_at = user_info.updated_password_at;
            var rangeDateNum = (time - password_at) / (1000 * 3600 * 24);
            if (rangeDateNum > day && this.wechat_pad === 'true') {
              console.log(1313131311311);
              this.$router.push('/overdue');
            } else {
              if (user_info.managed_circle == null || user_info.managed_circle.info == null) {
                if (router.history.current.name === 'perfect') {
                } else {
                  // router.replace({ path: '/perfect' });
                  // return false;
                }
              } else {
                if (user_info.managed_circle.status === 0 || user_info.managed_circle.status === -1) {
                  if (router.history.current.name === 'perfect') {
                  } else {
                    router.replace({ path: '/audit' });
                    return false;
                  }
                }
              }
              let route_name = router.history.current.name;
              if (route_name === 'login' || route_name === 'register' || route_name === 'forget' || route_name === 'verify') {
                if (user_info.managed_circle.status === -1 || user_info.managed_circle.status === 0) {
                  router.replace({ path: '/audit' });
                } else {
                  router.replace({ path: '/home' });
                }
              }
            }
          }
        })
        .catch((error) => {
          if (error.response.status === 403) {
            cache.removeLoginInfo();
            router.replace({ path: '/login' });
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
@import '~assets/less/auth/auth.less';
.register-btn {
  margin-top: 104px !important;
}
.bottom-hint-box {
  a {
    margin-left: 6px;
    color: #93999f;
    text-decoration: none;
  }
}
</style>
